<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h1 class="mb-4">
          Deployments
        </h1>
        <v-btn
          color="accent"
          disabled
          @click="initNewDeploy"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-divider />
      </v-col>
    </v-row>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        xs="12"
        sm="6"
        md="4"
      >
        <v-card>
          <v-card-title>
            <span class="headline">New Deployment</span>
            <!--<p>User: {{ currentUser.JWT_TOKEN }}</p>-->
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-select
                    v-model="formApplication"
                    :items="['beam3-web', 'beam3-lumen-api']"
                    label="Application*"
                    required
                  />
                  <v-select
                    v-model="formPullRequest"
                    :disabled="!formApplication.length"
                    :loading="loadingPullRequests"
                    :items="formattedPullRequests"
                    label="Pull Request*"
                    required
                  />
                  <v-select
                    v-model="formClient"
                    :items="[
                      'adobe',
                      'aon',
                      'anthem',  
                      'aah', 
                      'bain',                        
                      'baptisthealth',   
                      'beam',
                      'campsite',  
                      'cbre',
                      'chr',
                      'cigna',                      
                      'conoco',
                      'cookchildrens',  
                      'genentech',                      
                      'medsol',                         
                      'monigle', 
                      'nemours',
					            'nngroup',                         
                      'peloton',
                      'quest',                      
                      'optum',                                           
                      'salesforce',                      
                      'schneider-electric',
                      'sch',
                      'shc',                      
                      'som',                      
                      'spgi',  
                      'troweprice',                   
                      'uchealth',
                      'unc-health',                      
                      'unc-real-estate',                        
                      'unitedhealthcare',                  
                      'vertex',
					            'westmonroe',
                      'wtw'                      
                    ]"
                    label="Client*"
                    :disabled="!formPullRequest.length"
                    required
                  />
                  <v-select
                    v-model="formEnv"
                    :items="[
                      'qa1',
                      'qa2',
                      'qa3',
                      'qa4',
                      'qa5',
                      'qa6',
                      'qa7'
                    ]"
                    label="Build Environment*"
                    :disabled="!formClient.length"
                    required
                  />
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              id="submit-deploy"
              color="primary"
              :disabled="!submitEnabled"
              large
              @click.exact="handleDeploy"
            >
              Deploy
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <div class="text-center">
      <v-snackbar
        v-model="snackbar"
        :vertical="vertical"
      >
        {{ msgText }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="green"
            text
            v-bind="attrs"
            @click="snackbar = false"
            :multi-line="multiLine"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>

  </v-container>
</template>
<script>
import { buildApplication, getPullRequests } from '../services'

export default {
  name: 'deployments',
  data: () => ({
    deploymentError: false,
    deploymentLoading: false,
    dialog: false,
    errorPullRequests: false,
    formApplication: '',
    formClient: '',
    formPullRequest: '',
    formEnv: '',
    loadingPullRequests: false,
    openPullRequests: null,
    snackbar: false,
    msgText: 'Build started. To check status login to CircleCI for more information',
    vertical: true,
  }),
  computed: {
    qaEngineer () {
      return 'qa1'; //this.$store.state.User.data
    },
    formattedPullRequests () {
      return this.openPullRequests
        ? this.openPullRequests.map(pr => pr.head.ref)
        : null
    },
    submitEnabled () {
      return (
        Boolean(this.formPullRequest.length) &&
        Boolean(this.formClient.length) &&
        Boolean(this.formPullRequest.length) &&
        Boolean(this.formApplication.length)
      )
    },
    currentUser () {
      return this.$store.getters['User/userData']
    },
  },
  watch: {
    formApplication: {
      handler: function (value) {
        this.loadPullRequests(value)
      }
    }
  },
  methods: {
    async initNewDeploy () {
      this.dialog = true
    },
    async loadPullRequests (app) {
      this.dialog = true
      this.loadingPullRequests = true

      try {
        this.openPullRequests = await getPullRequests(app)
      } catch {
        this.errorPullRequests = true
      } finally {
        this.loadingPullRequests = false
      }
    },
    async handleDeploy () {
      this.dialog = false
      this.deploymentLoading = true

      try {
        await buildApplication({
          branch: this.formPullRequest,
          application: this.formApplication,
          client: this.formClient,
          //engineer: this.qaEngineer,
          env: this.formEnv,
          authToken: this.$store.getters['User/userData'].JWT_TOKEN
        }).then(result => {

          this.snackbar = true;
        });
      } catch (error) {
        this.deploymentError = true
      

      } finally {
        this.deploymentLoading = false
      }
    }
  }
}
</script>
