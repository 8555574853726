<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h1 class="mb-4">
          Deployments Demo/Product Environments
        </h1>
        <v-btn
          color="accent"
          disabled
          @click="initNewDeploy"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-divider />
      </v-col>
    </v-row>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        xs="12"
        sm="6"
        md="4"
      >
        <v-card>
          <v-card-title>
            <span class="headline">New Deployment Demo/Product</span>
            <!--<p>User: {{ currentUser.JWT_TOKEN }}</p>-->
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-select
                    v-model="formApplication"
                    :items="['beam3-web', 'beam3-lumen-api',]"
                    label="Application*"
                    required
                  />
                  <v-select
                    v-model="formBranch"
                    :disabled="!formApplication.length"
                    :items="['develop', 'master', 'demo', 'product']"
                    label="Branch*"
                    required
                  />
                  <v-select
                    v-model="formClient"
                    :items="[
                      'adobe',
                      'aon',
                      'anthem', 
                      'aah', 
                      'bain',                        
                      'baptisthealth',   
                      'beam',
                      'campsite', 
                      'cbre',
                      'chr',
                      'cigna',                      
                      'conoco',
                      'cookchildrens', 
                      'genentech',                      
                      'medsol',                          
                      'monigle', 
                      'nemours',                      
                      'nngroup',                                                                                                                                                                                                                             
                      'peloton',
                      'quest',                      
                      'optum',                                           
                      'salesforce',                      
                      'schneider-electric',
                      'sch',
                      'shc',                     
                      'som',                      
                      'spgi',
                      'troweprice',                     
                      'uchealth',
                      'unc-health',                      
                      'unc-real-estate',                     
                      'unitedhealthcare',                     
                      'vertex'                
                    ]"
                    label="Client*"
                    :disabled="!formBranch.length"
                    required
                  />
                  <v-select
                    v-model="formEnv"
                    :items="[
                      'demo',
                      'product'
                    ]"
                    label="Build Environment*"
                    :disabled="!formClient.length"
                    required
                  />
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              id="submit-deploy"
              color="primary"
              :disabled="!submitEnabled"
              large
              @click.exact="handleDeploy"
            >
              Deploy
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <div class="text-center">
      <v-snackbar
        v-model="snackbar"
        :vertical="vertical"
      >
        {{ msgText }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="green"
            text
            v-bind="attrs"
            @click="snackbar = false"
            :multi-line="multiLine"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>

  </v-container>
</template>
<script>
import { buildApplicationDemo, getPullRequests } from '../services'

export default {
  name: 'deployments',
  data: () => ({
    deploymentError: false,
    deploymentLoading: false,
    dialog: false,
    errorPullRequests: false,
    formApplication: '',
    formClient: '',
    formBranch: '',
    formEnv: '',
    loadingPullRequests: false,
    openPullRequests: null,
    snackbar: false,
    msgText: 'Build started. To check status login to CircleCI for more information',
    vertical: true,
  }),
  computed: {
    qaEngineer () {
      return 'demo'; //this.$store.state.User.data
    },
    formattedPullRequests () {
      return this.openPullRequests
        ? this.openPullRequests.map(pr => pr.head.ref)
        : null
    },
    submitEnabled () {
      return (
        Boolean(this.formBranch.length) &&
        Boolean(this.formClient.length) &&
        Boolean(this.formBranch.length) &&
        Boolean(this.formApplication.length)
      )
    },
    currentUser () {
      return this.$store.getters['User/userData']
    },
  },
  methods: {
    async initNewDeploy () {
      this.dialog = true
    },
    async handleDeploy () {
      this.dialog = false
      this.deploymentLoading = true

      try {
        await buildApplicationDemo({
          branch: this.formBranch,
          application: this.formApplication,
          client: this.formClient,
          //engineer: this.qaEngineer,
          env: this.formEnv,
          authToken: this.$store.getters['User/userData'].JWT_TOKEN
        }).then(result => {

          this.snackbar = true;
        });
      } catch (error) {
        this.deploymentError = true
      

      } finally {
        this.deploymentLoading = false
      }
    }
  }
}
</script>
